import React, { useState } from "react"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBar from "../components/hero-bar"

const RegistrationPage = () => {
  const [athleteFirst, setAthleteFirst] = useState("")
  const [athleteLast, setAthleteLast] = useState("")
  const [grade, setGrade] = useState("")
  const [gender, setGender] = useState("")
  const [tshirt, setTshirt] = useState("")
  const [parentFirst, setParentFirst] = useState("")
  const [parentLast, setParentLast] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [flexing, setFlexing] = useState("no")
  const [registrationtype, setRegistrationtype] = useState("")
  const [clubSport, setClubSport] = useState("")
  const [priority, setPriority] = useState("cavalry_team")
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showInvalid, setShowInvalid] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const validate = () => {
    if (
      athleteFirst === "" ||
      athleteLast === "" ||
      grade === "" ||
      gender === "" ||
      tshirt === "" ||
      parentFirst === "" ||
      parentLast === "" ||
      email === "" ||
      phone === "" ||
      flexing === "" ||
      registrationtype === ""
        // || clubSport === ""
    ) {
      setShowInvalid(true)
      return false
    }
    return true
  }

  const clear = () => {
    setAthleteFirst("")
    setAthleteLast("")
    setGrade("")
    setGender("")
    setTshirt("")
    setParentFirst("")
    setParentLast("")
    setEmail("")
    setPhone("")
    setFlexing("")
    setClubSport("")
    setPriority("")
    setRegistrationtype("")
    setShowError(false)
    setShowSuccess(false)
    setShowInvalid(false)
  }

  const handleSubmit = () => {
    setShowError(false)
    setShowSuccess(false)
    setShowInvalid(false)

    if (validate()) {
      setIsSaving(true)
      const fields = {
        fields: {
          full_name: `${athleteFirst} ${athleteLast}`,
          athlete_first: athleteFirst,
          athlete_last: athleteLast,
          grade,
          gender,
          tshirt_size: tshirt,
          parent_first: parentFirst,
          parent_last: parentLast,
          email,
          phone,
          flexing,
          registrationtype,
          club_sport: clubSport,
          priority: priority === "" ? 'cavalry_team' : priority,
        },
        typecast: true,
      }
      fetch("https://api.airtable.com/v0/apptCP1LHIMXKOkR7/registration", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fields),
      })
        .then(() => {
          clear()
          setShowSuccess(true)
        })
        .catch(() => setShowError(true))
        .finally(() => setIsSaving(false))
    }
  }

  return (
    <Layout>
      <SEO title="Registration" />
      <HeroBar subtitle="Registration" />
      <div className="has-background-white-ter has-margin-top-15">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <div className="has-margin-top-15 has-margin-15-mobile">
                <div className="tile">
                  <h1 className="title has-padding-bottom-5 why-cav">
                    Athlete Registration
                  </h1>
                </div>
                <div className="tile">
                  <h3 className="subtitle">
                    Register your athlete for their upcoming season!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns has-margin-bottom-35">
        <div className="column is-6 is-offset-3">
          <div className="content">
            {showSuccess && (
              <div className="column is-12 has-margin-top-25">
                <article className="message is-success">
                  <div className="message-body">
                    <div>
                      <strong>Saved!</strong>
                    </div>
                    <p>Your athlete has been registered.</p>
                  </div>
                </article>
              </div>
            )}
            {showError && (
              <div className="column is-12 has-margin-top-25">
                <article className="message is-danger">
                  <div className="message-body">
                    <div>
                      <strong>Uh oh!</strong>
                    </div>
                    <p>
                      Something went wrong trying to register your athlete.
                      Please try again shortly.
                    </p>
                  </div>
                </article>
              </div>
            )}
            {showInvalid && (
              <div className="column is-12 has-margin-top-25">
                <article className="message is-danger">
                  <div className="message-body">
                    <p>
                      All fields are required to register your athlete. Please
                      fill out all the fields and try again.
                    </p>
                  </div>
                </article>
              </div>
            )}

            <div className="column is-12 has-margin-top-25 has-margin-bottom-25">
              <div className="field">
                <div>
                  <span className="label has-margin-top-20">
                    Athlete Name <span className="has-text-primary">*</span>
                  </span>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="First"
                        name="ath_first"
                        value={athleteFirst}
                        onChange={e => {
                          setShowSuccess(false)
                          setShowError(false)
                          setAthleteFirst(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Last"
                        name="ath_last"
                        value={athleteLast}
                        onChange={e => setAthleteLast(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="grade" className="label has-margin-top-20">
                  Grade <span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                      id="grade"
                      name="grade"
                      value={grade}
                      onChange={e => setGrade(e.target.value)}
                    >
                      <option value="">select one</option>
                      <option value="kindergarten">Kindergarten</option>
                      <option value="first">First</option>
                      <option value="second">Second</option>
                      <option value="third">Third</option>
                      <option value="third">Third</option>
                      <option value="fourth">Fourth</option>
                      <option value="fifth">Fifth</option>
                      <option value="sixth">Sixth</option>
                      <option value="seventh">Seventh</option>
                      <option value="eighth">Eighth</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="gender" className="label has-margin-top-20">
                  Gender <span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={e => setGender(e.target.value)}
                    >
                      <option value="">select one</option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="registrationtype" className="label has-margin-top-20">
                  Register for: <span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                        id="registrationtype"
                        name="registrationtype"
                        value={registrationtype}
                        onChange={e => setRegistrationtype(e.target.value)}
                    >
                      <option value="">select one</option>
                      <option value="league">League and Acadamies</option>
                      <option value="acadamiesOnly">Acadamies Only</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="tshirt" className="label has-margin-top-20">
                  T-shirt Size <span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                      id="tshirt"
                      name="tshirt"
                      value={tshirt}
                      onChange={e => setTshirt(e.target.value)}
                    >
                      <option value="">select one</option>
                      <option value="youth_small">Youth Small</option>
                      <option value="youth_medium">Youth Medium</option>
                      <option value="youth_large">Youth Large</option>
                      <option value="youth_xlarge">Youth X-Large</option>
                      <option value="small">Small</option>
                      <option value="medium">Medium</option>
                      <option value="large">Large</option>
                      <option value="xlarge">X-Large</option>
                      <option value="xxlarge">XX-Large</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div>
                  <span className="label has-margin-top-20">
                    Parent Name <span className="has-text-primary">*</span>
                  </span>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="First"
                        name="parent_first"
                        value={parentFirst}
                        onChange={e => setParentFirst(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Last"
                        name="parent_last"
                        value={parentLast}
                        onChange={e => setParentLast(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="email" className="label">
                  Email <span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email address"
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="phone" className="label">
                  Phone <span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="tel"
                    placeholder="Phone Number"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
              </div>
              {/*<div className="field">*/}
              {/*  <label htmlFor="flexing" className="label">*/}
              {/*    If available, is your athlete interested “flexing” between two*/}
              {/*    teams in their age group?{" "}*/}
              {/*    <span className="has-text-primary">*</span>*/}
              {/*  </label>*/}
              {/*  <div className="control">*/}
              {/*    <div className="select">*/}
              {/*      <select*/}
              {/*        id="flexing"*/}
              {/*        name="flexing"*/}
              {/*        value={flexing}*/}
              {/*        onChange={e => setFlexing(e.target.value)}*/}
              {/*      >*/}
              {/*        <option value="">select one</option>*/}
              {/*        <option value="yes">Yes</option>*/}
              {/*        <option value="no">No</option>*/}
              {/*      </select>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* ******************  ALSO UPDATE LINE 37 ************************  */}
              {/*<div className="field">*/}
              {/*  <label htmlFor="club_sport" className="label">*/}
              {/*    Do you participate on another club sport team?{" "}*/}
              {/*    <span className="has-text-primary">*</span>*/}
              {/*  </label>*/}
              {/*  <div className="control">*/}
              {/*    <div className="select">*/}
              {/*      <select*/}
              {/*        id="club_sport"*/}
              {/*        name="club_sport"*/}
              {/*        value={clubSport}*/}
              {/*        onChange={e => setClubSport(e.target.value)}*/}
              {/*      >*/}
              {/*        <option value="">select one</option>*/}
              {/*        <option value="yes">Yes</option>*/}
              {/*        <option value="no">No</option>*/}
              {/*      </select>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* ******************  ALSO UPDATE LINE 37 ************************  */}
              {clubSport === "yes" && (
                <div className="field">
                  <label htmlFor="priority" className="label">
                    Which team will be your priority
                  </label>
                  <div className="control">
                    <div className="select">
                      <select
                        id="priority"
                        name="priority"
                        value={priority}
                        onChange={e => setPriority(e.target.value)}
                      >
                        <option value="cavalry_team">Cavalary team</option>
                        <option value="club_team">club team</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="field is-grouped is-pulled-right has-margin-top-20 has-margin-bottom-25">
                <div className="control" onClick={clear}>
                  <button
                    className="button is-primary is-light"
                    disabled={isSaving}
                  >
                    Clear
                  </button>
                </div>
                <div className="control">
                  <button
                    className="button is-primary"
                    onClick={handleSubmit}
                    disabled={isSaving}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RegistrationPage
